define([
    'lodash',
    'prop-types',
    'componentsCore',
    'skins',
    'coreUtils',
    'santa-core-utils',
    'santa-components',
    'galleriesCommon',
    'displayer',
    'slideShowGallery/skins/skins.json'
], function (
    _,
    PropTypes,
    componentsCore,
    skinsPackage,
    coreUtils,
    coreUtilsLib,
    santaComponents,
    galleriesCommon,
    displayer,
    skinsJson
) {
    'use strict';

    const matrixScalingCalculations = coreUtils.matrixScalingCalculations;
    const galleriesHelperFunctions = galleriesCommon.utils.galleriesHelperFunctions;
    const galleriesCommonLayout = coreUtils.galleriesCommonLayout;


    function getNextItemIndex(currentItemIndex, numItems) {
        return normalizeIndex(currentItemIndex + 1, numItems);
    }

    function getPrevItemIndex(currentItemIndex, numItems) {
        return normalizeIndex(currentItemIndex - 1, numItems);
    }

    function normalizeIndex(index, numItems) {
        return (index % numItems + numItems) % numItems; // eslint-disable-line no-mixed-operators
    }

    function setCounterText(currentIndex, totalItems) {
        return `${String(currentIndex + 1)}/${String(totalItems)}`;
    }

    function adjustFlexibleHeight(sizeAfterScaling, imageMode, propsHeight) {
        if (imageMode === 'flexibleHeight') {
            return sizeAfterScaling.displayerSize.height;
        }
        return propsHeight;
    }

    function getPublicState(state) {
        return {
            currentIndex: _.get(state, 'currentIndex', 0),
            isPlaying: _.get(state, '$slideshow', 'autoplayOff') === 'autoplayOn'
        };
    }

    /**
     * @class components.SlideShowGallery
     * @extends {core.animationsMixin}
     * @extends {ReactCompositeComponent}
     * @property {comp.properties} props
     */
    const slideShowGallery = {
        displayName: 'SlideShowGallery',
        mixins: [
            componentsCore.mixins.skinBasedComp,
            galleriesCommon.mixins.galleryAutoPlayMixin,
            santaComponents.mixins.animationsMixin,
            coreUtilsLib.timersMixins.timeoutsMixin,
            componentsCore.mixins.skinInfo,
            santaComponents.mixins.compStateMixin(getPublicState),
            componentsCore.mixins.createChildComponentMixin
        ],
        statics: {
            compSpecificIsDomOnlyOverride: () => false,
            behaviors: {
                nextSlide: {methodName: 'next'},
                prevSlide: {methodName: 'prev'}
            }
        },

        propTypes: _.assign({
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            skin: santaComponents.santaTypesDefinitions.Component.skin.isRequired,
            style: santaComponents.santaTypesDefinitions.Component.style.isRequired,
            currentUrlPageId: santaComponents.santaTypesDefinitions.currentUrlPageId.isRequired,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView.isRequired,
            isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice.isRequired,
            isTabletDevice: santaComponents.santaTypesDefinitions.Device.isTabletDevice.isRequired,
            isZoomOpened: santaComponents.santaTypesDefinitions.isZoomOpened.isRequired,
            isPlayingAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isPlayingAllowed.isRequired,
            shouldResetGalleryToOriginalState: santaComponents.santaTypesDefinitions.RenderFlags.shouldResetGalleryToOriginalState.isRequired,
            windowTouchEvents: santaComponents.santaTypesDefinitions.SiteAspects.windowTouchEvents.isRequired,

            registerReLayout: PropTypes.func
        }, santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(displayer)),

        getInitialState() {
            this.prevCompProps = _.cloneDeep(this.props.compProp);
            return this.getInitStateAndResetInstance(this.props);
        },

        getInitStateAndResetInstance(props) {
            const propsToUse = props || this.props;
            this.shouldResetGalleryToOriginalState = propsToUse.shouldResetGalleryToOriginalState;
            propsToUse.windowTouchEvents.registerToWindowTouchEvent('touchStart', this);
            this.isAnimating = false;

            this.getButtonsState = this.getButtonsState || _.noop;

            return _.assign(getPublicState(), {
                $mobile: propsToUse.isMobileDevice || propsToUse.isTabletDevice() ? 'mobile' : 'notMobile',
                $displayDevice: propsToUse.isMobileView ? 'mobileView' : 'desktopView',
                displayerPanelState: 'notShowPanel',
                $touchRollOverSupport: 'touchRollOut',
                $animationInProcess: null //for automation
            }, this.getButtonsState());
        },
        componentDidMount() {
            this.updateAutoplayState();
        },
        componentWillReceiveProps(nextProps) { // eslint-disable-line complexity
            let autoPlayPropChanged = false;
            const newState = {
                $mobile: nextProps.isMobileDevice || nextProps.isTabletDevice() ? 'mobile' : 'notMobile',
                $displayDevice: nextProps.isMobileView ? 'mobileView' : 'desktopView'
            };
            if (this.prevCompProps.autoplay !== nextProps.compProp.autoplay) {
                const shouldAutoPlay = nextProps.compProp.autoplay && !this.props.isZoomOpened && this.props.isPlayingAllowed ? 'autoplayOn' : 'autoplayOff';
                autoPlayPropChanged = true;
                newState.shouldAutoPlay = nextProps.compProp.autoplay;
                newState.$slideshow = shouldAutoPlay;
            }
            this.prevCompProps = _.cloneDeep(nextProps.compProp);
            _.assign(newState, this.getButtonsState());

            this.setState(newState, function () {
                if (autoPlayPropChanged) {
                    this.updateAutoplayState();
                    this.handleAction(newState.$slideshow);
                }
            }.bind(this));

            if (this.shouldResetGalleryToOriginalState !== nextProps.shouldResetGalleryToOriginalState) {
                if (this.shouldResetGalleryToOriginalState && this.props.compProp.imageMode === 'flexibleHeight' && this.resetGalleryState) {
                    this.resetGalleryState(nextProps);
                }
                this.shouldResetGalleryToOriginalState = nextProps.shouldResetGalleryToOriginalState;
            }
        },
        componentWillUnmount() {
            this.props.windowTouchEvents.unregisterFromWindowTouchEvent('touchStart', this);
        },
        getSkinProperties() { // eslint-disable-line complexity
            const compData = this.props.compData;
            let imageData, sizeAfterScaling, heightToSet;
            const hasItems = compData.items && compData.items.length > 0;
            if (hasItems) {
                imageData = compData.items[this.state.currentIndex];
                sizeAfterScaling = this.getDisplayerSizeAfterScaling(imageData);
                heightToSet = adjustFlexibleHeight(sizeAfterScaling, this.props.compProp.imageMode, this.props.style.height);
            }

            const mouseHandler = hasItems ? this.hideShowPanel : _.noop;
            const refData = {
                'itemsContainer': {
                    children: hasItems ? this.generateNextPagesIfNeeded().concat([this.createDisplayer(imageData, this.state.currentIndex)]) : [],
                    style: {height: '100%'},
                    'data-gallery-id': this.props.id
                },
                'buttonPrev': {
                    onClick: this.prev,
                    style: {visibility: !this.props.compProp.isHidden && hasItems && this.props.compProp.showNavigation ? 'visible' : 'hidden'},
                    'data-gallery-id': this.props.id
                },
                'buttonNext': {
                    onClick: this.next,
                    style: {visibility: !this.props.compProp.isHidden && hasItems && this.props.compProp.showNavigation ? 'visible' : 'hidden'},
                    'data-gallery-id': this.props.id
                },
                'counter': {
                    children: setCounterText(this.state.currentIndex, this.props.compData.items.length),
                    style: {visibility: !this.props.compProp.isHidden && this.props.compProp.showCounter ? 'visible' : 'hidden'},
                    'data-gallery-id': this.props.id
                },
                'border': {
                    style: {height: heightToSet},
                    'data-gallery-id': this.props.id
                },
                'autoplay': {
                    onClick: this.toggleAutoPlay,
                    style: {cursor: 'pointer', visibility: this.shouldShowAutoPlay() ? 'visible' : 'hidden'},
                    'data-gallery-id': this.props.id
                },
                '': {
                    style: {height: heightToSet, 'overflow': 'hidden'},
                    onMouseEnter: mouseHandler,
                    onMouseLeave: mouseHandler,
                    onMouseMove: mouseHandler,
                    onTouchStart: this.onComponentTouchStart,
                    'data-gallery-id': this.props.id,
                    'data-image-mode': this.props.compProp.imageMode,
                    'data-height-diff': galleriesHelperFunctions.getSkinHeightDiff(this.props.skin),
                    'data-width-diff': galleriesHelperFunctions.getSkinWidthDiff(this.props.skin),
                    'data-text-alignment': this.props.compProp.alignText
                }
            };

            if (this.props.compProp.imageMode === 'flexibleHeight') {
                galleriesCommonLayout.updateSkinPropsForFlexibleHeightGallery(refData, heightToSet);
            }

            return refData;
        },
        onComponentTouchStart(event) {
            this.hideShowPanel(event);
            if (this.state.$touchRollOverSupport === 'touchRollOut') {
                this.setState({
                    $touchRollOverSupport: 'touchRollOver'
                });
            }
        },
        onWindowTouchStart(event) {
            // Check that the click hasn't been done anywhere on the component
            if (this.state.$touchRollOverSupport === 'touchRollOver' && event.target.getAttribute('data-gallery-id') !== this.props.id) {
                this.hideShowPanel({type: 'mouseleave', target: event.target});
                this.setState({
                    $touchRollOverSupport: 'touchRollOut'
                });
            }
        },
        hideShowPanel(event, reactObj, changeOnlyChildState) {
            const newState = event.type === 'mouseleave' ? 'notShowPanel' : 'showPanel';
            const currImageData = this.props.compData.items[this.state.currentIndex];
            const currentImageRef = currImageData.id;
            if (this.refs[currentImageRef].getPanelState() !== newState) {
                this.refs[currentImageRef].setPanelState(newState);
            }
            if (!changeOnlyChildState && newState !== this.state.displayerPanelState) {
                this.setState({
                    displayerPanelState: newState
                });
            }
        },
        prev(callback) {
            const isReverse = this.props.compProp.reverse;
            this.moveSlide(!isReverse, callback);
        },
        next(callback) {
            const isReverse = this.props.compProp.reverse;
            this.moveSlide(isReverse, callback);
        },
        moveSlide(isPrev, callback) {
            if (this.isAnimating) {
                return false;
            }
            const nextOrPrevIndex = isPrev ? getPrevItemIndex(this.state.currentIndex, this.props.compData.items.length) : getNextItemIndex(this.state.currentIndex, this.props.compData.items.length);
            if (this.state.currentIndex === nextOrPrevIndex) {
                return false;
            }

            if (this.props.registerReLayout) {
                this.props.registerReLayout();
            } else {
                this.registerReLayout();
            }

            this.hideShowPanel({type: 'mouseleave', target: {id: ''}}, {}, true);
            const nextOrPrevIndexDataId = this.props.compData.items[nextOrPrevIndex].id;
            const currImageDataId = this.props.compData.items[this.state.currentIndex].id;
            const nextOrPrevImageRef = nextOrPrevIndexDataId;
            const currentImageRef = currImageDataId;

            this.setImageTransitionPhase(nextOrPrevIndex, 'transIn');
            this.setImageTransitionPhase(this.state.currentIndex, 'transOut');

            const transitionMap = {
                'swipeVertical': 'SlideVertical',
                'swipeHorizontal': 'SlideHorizontal',
                'crossfade': 'CrossFade',
                'outIn': 'OutIn',
                'none': 'NoTransition'
            };
            const transDuration = this.props.compProp.transition === 'none' ? 0 : this.props.compProp.transDuration;
            this.setState({$animationInProcess: 'animationInProcess'});

            const sequence = this.sequence();

            if (this.props.compProp.imageMode === 'flexibleHeight') {
                const sizeAfterScaling = this.getDisplayerSizeAfterScaling(nextOrPrevIndexDataId);
                sequence.add('', 'BaseDimensions', this.props.compProp.transDuration, 0, {to: {height: sizeAfterScaling.displayerSize.height}});
            }

            sequence
                .add('itemsContainer', 'BaseDimensions', 0, 0, {to: {zIndex: 0}}, 0)
                .add(nextOrPrevImageRef, 'BaseFade', 0, 0, {to: {autoAlpha: 1}}, 0)
                .add({
                    sourceRefs: currentImageRef,
                    destRefs: nextOrPrevImageRef
                }, transitionMap[this.props.compProp.transition], transDuration, 0, {reverse: isPrev}, 0)
                .add('itemsContainer', 'BaseDimensions', 0, 0, {to: {clearProps: 'zIndex', immediateRender: false}})
                .onStartAll(function () {
                    this.isAnimating = true;
                }.bind(this))
                .onCompleteAll(function () {
                    this.animationCompleteCallback(nextOrPrevIndex, callback);
                }.bind(this))
                .execute();
        },
        setImageTransitionPhase(imageIndex, transitionPhase) {
            const imageDataId = this.props.compData.items[imageIndex].id;
            const imageRefString = imageDataId;
            this.refs[imageRefString].setTransitionPhase(transitionPhase);
        },
        animationCompleteCallback(nextOrPrevIndex, callback) {
            this.isAnimating = false;

            this.setImageTransitionPhase(nextOrPrevIndex, 'noTransition');
            this.setImageTransitionPhase(this.state.currentIndex, 'noTransition');

            this.setState({
                currentIndex: nextOrPrevIndex,
                $animationInProcess: null
            }, function () {
                this.updateAutoplayState();
                const currentImageData = this.props.compData.items[this.state.currentIndex];
                this.handleAction(coreUtils.siteConstants.ACTION_TYPES.IMAGE_CHANGED, {item: currentImageData, imageIndex: this.state.currentIndex});
                if (_.isFunction(callback)) {
                    callback();
                }
            }.bind(this));
        },
        getDisplayerSizeAfterScaling(displayerData) {
            return matrixScalingCalculations.getSizeAfterScaling({
                itemHeight: this.props.style.height - galleriesHelperFunctions.getSkinHeightDiff(this.props.skin),
                itemWidth: this.props.style.width - galleriesHelperFunctions.getSkinWidthDiff(this.props.skin),
                displayerData,
                imageMode: this.props.compProp.imageMode,
                heightDiff: this.getDisplayerHeightDiff(),
                widthDiff: this.getDisplayerWidthDiff(),
                bottomGap: this.getBottomGap()
            });
        },
        generateNextPagesIfNeeded() {
            let nextImageData;
            let prevImageData;
            const diplayersArr = [];
            const nextIndex = getNextItemIndex(this.state.currentIndex, this.props.compData.items.length);
            if (nextIndex !== this.state.currentIndex) {
                nextImageData = this.props.compData.items[nextIndex];
            }
            const prevIndex = getPrevItemIndex(this.state.currentIndex, this.props.compData.items.length);
            if (prevIndex !== this.state.currentIndex && prevIndex !== nextIndex) {
                prevImageData = this.props.compData.items[prevIndex];
            }

            if (nextImageData) {
                diplayersArr.push(this.createDisplayer(nextImageData, nextIndex, {visibility: 'hidden'}));
            }
            if (prevImageData) {
                diplayersArr.push(this.createDisplayer(prevImageData, prevIndex, {visibility: 'hidden'}));
            }
            return diplayersArr;
        },
        createDisplayer(displayerData, index, additionalstyle) {
            const sizeAfterScaling = this.getDisplayerSizeAfterScaling(displayerData);
            return this.createChildComponent(displayerData,
                'wysiwyg.viewer.components.Displayer',
                'imageItem',
                {
                    key: displayerData.id,
                    ref: displayerData.id,
                    id: this.props.id + displayerData.id,
                    currentUrlPageId: this.props.currentUrlPageId,
                    galleryDataId: this.props.compData.id,
                    galleryId: this.props.id,
                    imageWrapperSize: sizeAfterScaling.imageWrapperSize,
                    showPanelState: additionalstyle ? 'notShowPanel' : this.state.displayerPanelState,
                    heightDiff: this.getDisplayerHeightDiff(),
                    widthDiff: this.getDisplayerWidthDiff(),
                    bottomGap: this.getBottomGap(),
                    imageIndex: index,
                    style: _.merge({
                        width: sizeAfterScaling.displayerSize.width,
                        height: sizeAfterScaling.displayerSize.height,
                        position: 'absolute',
                        left: 0,
                        top: 0
                    }, additionalstyle)
                }
            );
        },
        getDisplayerHeightDiff() {
            const displayerSkin = this.getSkinExports().imageItem.skin;
            const displayerSkinParams = this.getParams(['topPadding', 'imgHeightDiff'], displayerSkin);
            return galleriesHelperFunctions.getDisplayerHeightDiff(skinsPackage.skinsMap.get(displayerSkin, this.props.isExperimentOpen), displayerSkinParams, this.state.$displayDevice);
        },

        getDisplayerWidthDiff() {
            const displayerSkin = this.getSkinExports().imageItem.skin;
            return galleriesHelperFunctions.getDisplayerWidthDiff(skinsPackage.skinsMap.get(displayerSkin, this.props.isExperimentOpen), this.state.$displayDevice);
        },
        getBottomGap() {
            const skin = skinsPackage.skinsMap.get(this.props.skin, this.props.isExperimentOpen);
            return skin.exports && skin.exports.bottomGap || 0; // eslint-disable-line no-mixed-operators
        }
    };

    skinsPackage.skinsMap.addBatch(skinsJson);

    return slideShowGallery;
});
